.App {
  text-align: center;
}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Infra-Header {
  margin-top: 20px;
}

.headerImage {
  object-fit: cover;
  height: 70vh;
  width: 100%;
}

@media (max-width: 900px) {
  .headerImage {
    object-fit: cover;
    height: 34vh;
  }
}

.partsDisplay {
  padding-top: 40px;
  padding-bottom: 40px;
}

.productCardButton {
  height: 60px;
  width: 100%;
  bottom: 0px;
}
